<template>
<form v-on:submit="submitAvatar()">
  <div v-if="user.avatar" class="edit-icon-container">

    <div class="rp__inner flex justify-content-around">
      <div class="flex flex-wrap">

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="hairType">Coiffure:</label>
            
            <select id="hairType" 
              class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              @change="updateAvatar"
              v-model="userAvatar.hairType">
              <option value="NoHair">Chauve</option>
              <option value="Hat">Chapeau</option>
              <option value="Hijab">Hijab</option>
              <option value="Turban">Turban</option>
              <option value="WinterHat1">Bonnet 1</option>
              <option value="WinterHat2">Bonnet 2</option>
              <option value="WinterHat3">Bonnet 3</option>
              <option value="WinterHat4">Bonnet 4</option>
              <option value="LongHairStraight">Cheveux longs 1</option>
              <option value="LongHairStraight2">Cheveux longs 2</option>
              <option value="LongHairStraightStrand">Cheveux longs 3</option>
              <option value="LongHairBigHair">Cheveux longs 4</option>
              <option value="LongHairBob">Cheveux longs Bob</option>
              <option value="LongHairBun">Cheveux longs Bun</option>
              <option value="LongHairCurly">Cheveux longs frisé</option>
              <option value="LongHairCurvy">Cheveux longs bouclés</option>
              <option value="LongHairDreads">Cheveux longs Dreads</option>
              <option value="LongHairFro">Coupe afro</option>
              <option value="LongHairFroBand">Coupe afro avec bandeau</option>
              <option value="LongHairNotTooLong">Cheveux mi-longs</option>
              <option value="ShortHairShortCurly">Cheveux courts 1</option>
              <option value="ShortHairShortFlat">Cheveux courts 2</option>
              <option value="ShortHairShortRound">Cheveux courts 3</option>
              <option value="ShortHairShortWaved">Cheveux courts 4</option>
              <option value="ShortHairDreads01">Cheveux courts Dreads 1</option>
              <option value="ShortHairDreads02">Cheveux courts Dreads 2</option>
              <option value="ShortHairFrizzle">Cheveux courts méche</option>
              <option value="ShortHairTheCaesar">Cheveux rasés 1</option>
              <option value="ShortHairTheCaesarSidePart">Cheveux rasés 2</option>
            </select>
          </div>
        </div>

        <div class="rp__input" v-if="hairColor">
          <div id="hairColorDiv" class="flex items-center border-b border-teal-500 py-2">
            <label for="hairColor">Couleur de cheveux:</label>

            <select id="hairColor" v-model="userAvatar.hairColor" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Auburn">Auburn</option>
              <option value="Black">Noirs</option>
              <option value="Blonde">Châtains</option>
              <option value="BlondeGolden">Blonds</option>
              <option value="Platinum">Blonds Platines</option>
              <option value="Brown">Bruns</option>
              <option value="BrownDark">Bruns foncés</option>
              <option value="PastelPink">Rose</option>
              <option value="Red">Roux</option>
              <option value="SilverGray">Gris</option>
            </select>
          </div>
        </div>

        <div class="rp__input" v-if="hatColor">
          <div id="hatColorDiv" class="flex items-center border-b border-teal-500 py-2">
            <label for="hatColor">Couleur:</label>

            <select id="hatColor" v-model="userAvatar.hatColor" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Black">Noir</option>
              <option value="Blue02">Bleu </option>
              <option value="PastelBlue">Bleu clair</option>
              <option value="Blue03">Bleu foncé</option>
              <option value="Gray01">Gris</option>
              <option value="Gray02">Gris foncé</option>
              <option value="PastelGreen">Vert</option>
              <option value="PastelOrange">Orange</option>
              <option value="PastelRed">Rose</option>
              <option value="PastelYellow">Jaune</option>
              <option value="Pink">Magenta</option>
              <option value="Red">Rouge</option>
              <option value="White">Blanc</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="accessoriesType">Lunettes:</label>

            <select id="accessoriesType" v-model="userAvatar.accessoriesType" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Blank">Aucune</option>
              <option value="Prescription01">Lunettes 1</option>
              <option value="Prescription02">Lunettes 2</option>
              <option value="Round">Lunettes rondes</option>
              <option value="Sunglasses">Lunettes de soleil 1</option>
              <option value="Wayfarers">Lunettes de soleil 2</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
              <label for="facialHairType">Pilosité faciale:</label>

              <select id="facialHairType" v-model="userAvatar.facialHairType" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
                <option value="Blank">Aucune</option>
                <option value="BeardLight">Barbe 1</option>
                <option value="BeardMedium">Barbe 2</option>
                <option value="BeardMajestic">Barbe 3</option>
                <option value="MoustacheMagnum">Moustache</option>
                <option value="MoustacheFancy">Moustache fancy</option>
              </select>
          </div>
        </div>

        <div class="rp__input" v-if="facialHairColor">
          <div id="facialHairColorDiv" class="flex items-center border-b border-teal-500 py-2">
            <label for="facialHairColor">Couleur:</label>

            <select id="facialHairColor" v-model="userAvatar.facialHairColor" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Auburn">Auburn</option>
              <option value="Black">Noire</option>
              <option value="Blonde">châtain</option>
              <option value="BlondeGolden">Blonde</option>
              <option value="Platinum">Blonde platine</option>
              <option value="Brown">Brune</option>
              <option value="BrownDark">Brune foncé</option>
              <option value="Red">Roux</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="clotheType">Vétements:</label>

            <select id="clotheType" v-model="userAvatar.clotheType" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="BlazerShirt">Blazer</option>
              <option value="Hoodie">Pull</option>
              <option value="ShirtCrewNeck">T-shirt</option>
              <option value="ShirtVNeck">T-shirt col v</option>
              <option value="ShirtScoopNeck">T-shirt col bateau</option>
            </select>
          </div>
        </div>

        <div class="rp__input" v-if="clotheColor">
          <div class="flex items-center border-b border-teal-500 py-2" id="clotheColorDiv">
            <label for="clotheColor">Couleur:</label>

            <select id="clotheColor" v-model="userAvatar.clotheColor" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Black">Noir</option>
              <option value="Blue02">Bleu </option>
              <option value="PastelBlue">Bleu clair</option>
              <option value="Blue03">Bleu foncé</option>
              <option value="Gray01">Gris</option>
              <option value="Gray02">Gris foncé</option>
              <option value="PastelGreen">Vert</option>
              <option value="PastelOrange">Orange</option>
              <option value="PastelRed">Rose</option>
              <option value="PastelYellow">Jaune</option>
              <option value="Pink">Magenta</option>
              <option value="Red">Rouge</option>
              <option value="White">Blanc</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="eyeType">Yeux:</label>

            <select id="eyeType" v-model="userAvatar.eyeType" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Default">Normaux</option>
              <option value="Close">Fermés</option>
              <option value="Squint">plissés</option>
              <option value="Surprised">surpris</option>
              <option value="Wink">Clin d'oeil</option>
              <option value="Cry">Larme</option>
              <option value="Dizzy">Étourdi</option>
              <option value="Happy">Joyeux</option>
              <option value="Hearts">Coeurs</option>
              <option value="EyeRoll">Vers le haut</option>
              <option value="Side">Vers le côté</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="eyebrowType">Sourcils:</label>

            <select id="eyebrowType" v-model="userAvatar.eyebrowType" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Default">Normaux</option>
              <option value="Angry">Froncés</option>
              <option value="SadConcerned">Triste</option>
              <option value="UpDown">Haussés</option>
              <option value="UnibrowNatural">Monosourcil</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="mouthType">Bouche:</label>

            <select id="mouthType" v-model="userAvatar.mouthType" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Default">Normale</option>
              <option value="Smile">Sourire</option>
              <option value="Twinkle">Heureux</option>
              <option value="Sad">Triste</option>
              <option value="Serious">Sérieux</option>
              <option value="Grimace">Grimace</option>
              <option value="Disbelief">Déçu</option>
              <option value="ScreamOpen">Cri</option>
              <option value="Eating">Joues gonflées</option>
              <option value="Tongue">Tirer la langue</option>
              <option value="Vomit">Vomit</option>
            </select>
          </div>
        </div>

        <div class="rp__input">
          <div class="flex items-center border-b border-teal-500 py-2">
            <label for="skinColor">Peau:</label>

            <select id="skinColor" v-model="userAvatar.skinColor" @change="updateAvatar" class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none">
              <option value="Pale">Pale</option>
              <option value="Light">Claire</option>
              <option value="Yellow">Jaune</option>
              <option value="Brown">Basané</option>
              <option value="DarkBrown">Brun foncé</option>
              <option value="Black">Noire</option>
            </select>
          </div>
        </div>
      </div>
    </div>

  <div class="btn-save">
    <button class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3" type="submit">
      Enregistrer
    </button>
  </div>

  </div>
</form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EditUserIcon',
  components: {},
  props: ['user'],
  data () {
    return {
      userAvatar: {
        hairType: 'NoHair',
        hairColor: 'Auburn',
        hatColor: 'Black',
        accessoriesType: 'Blank',
        facialHairType: 'Blank',
        facialHairColor: 'Auburn',
        clotheType: 'BlazerShirt',
        clotheColor: 'Black',
        eyeType: 'Default',
        eyebrowType: 'Default',
        mouthType: 'Default',
        skinColor: 'Pale'
      },
      hairColor: false,
      hatColor: false,
      facialHairColor: false,
      clotheColor: false,
      id:'',
      url:''
    }
  },
  async created () {
    setTimeout(() => {
      this.setAvatar(this.user.avatar)
    }, 300)
  },
  methods: {
    submitAvatar: async function () {
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
      let newData = {
        url: (this.url === null ? this.user.avatarUrl : this.url),
        avatar: this.userAvatar
      }
      axios.put(`/api/user/updateAvatar/${this.user._id}?${query}`, newData)
    },
    setAvatar: function (data) {
      if(data.hairType) this.userAvatar.hairType = data.hairType
      if(data.accessoriesType) this.userAvatar.accessoriesType = data.accessoriesType
      if(data.hairColor) this.userAvatar.hairColor = data.hairColor
      if(data.facialHairColor) this.userAvatar.facialHairColor = data.facialHairColor
      if(data.hatColor) this.userAvatar.hatColor = data.hatColor
      if(data.facialHairType) this.userAvatar.facialHairType = data.facialHairType
      if(data.clotheType) this.userAvatar.clotheType = data.clotheType
      if(data.clotheColor) this.userAvatar.clotheColor = data.clotheColor
      if(data.eyeType) this.userAvatar.eyeType = data.eyeType
      if(data.eyebrowType) this.userAvatar.eyebrowType = data.eyebrowType
      if(data.mouthType) this.userAvatar.mouthType = data.mouthType
      if(data.skinColor) this.userAvatar.skinColor = data.skinColor

      this.url = this.user.avatarUrl

      this.checkTop();
      this.checkFacial();
      this.checkClothes();
    },
    updateAvatar: function () {
        let avatarSrc = 'https://avataaars.io/?avatarStyle=Circle&'+
            `topType=${this.userAvatar.hairType}&`+
            `accessoriesType=${this.userAvatar.accessoriesType}&`+
            `hairColor=${this.userAvatar.hairColor}&`+
            `facialHairColor=${this.userAvatar.facialHairColor}&`+
            `hatColor=${this.userAvatar.hatColor}&`+
            `facialHairType=${this.userAvatar.facialHairType}&`+
            `clotheType=${this.userAvatar.clotheType}&`+
            `clotheColor=${this.userAvatar.clotheColor}&`+
            `eyeType=${this.userAvatar.eyeType}&`+
            `eyebrowType=${this.userAvatar.eyebrowType}&`+
            `mouthType=${this.userAvatar.mouthType}&`+
            `skinColor=${this.userAvatar.skinColor}`

        document.getElementById("myAvatar").src = avatarSrc
        this.url = avatarSrc

        this.checkTop();
        this.checkFacial();
        this.checkClothes();
    },
    checkTop: function () {
        const hair = ["LongHairBigHair","LongHairBob","LongHairBun","LongHairCurly","LongHairCurvy","LongHairDreads","LongHairFro","LongHairFroBand","LongHairNotTooLong","LongHairStraight","LongHairStraight2","LongHairStraightStrand","ShortHairDreads01","ShortHairDreads02","ShortHairFrizzle","ShortHairShortCurly","ShortHairShortFlat","ShortHairShortRound","ShortHairShortWaved","ShortHairTheCaesar","ShortHairTheCaesarSidePart"];
        const hat = ["Hijab","Turban","WinterHat1","WinterHat2","WinterHat3","WinterHat4"];

        if (hair.includes(this.userAvatar.hairType)) {
            this.hairColor = true;
            this.hatColor = false;
        } else if (hat.includes(this.userAvatar.hairType)) {
            this.hairColor = false;
            this.hatColor = true;
        } else {
            this.hairColor = false;
            this.hatColor = false;
        }
    },
    checkFacial: function () {
        const facialHair = ["BeardMedium","BeardLight","BeardMajestic","MoustacheFancy","MoustacheMagnum"];

        if (facialHair.includes(this.userAvatar.facialHairType)) {
            this.facialHairColor = true;
        } else {
            this.facialHairColor = false;
        }
    },
    checkClothes: function () {
        if (this.userAvatar.clotheType === "BlazerShirt") {
            this.clotheColor = false;
        } else {
            this.clotheColor = true;
        }
    }
  }
}

</script>

<style scoped>
.edit-icon-container {
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  min-width: 110px;
  width: 100%;
  margin-right: 10px;
  align-self: center;
}

.icon-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: left;
}

select {
  min-width: 240px;
}

.btn-save {
  margin: 0 auto;
}

@media screen and (max-width: 480px) {
  select {
    min-width: 180px;
  }

  .icon-select {
   flex-direction: column;
  }

  .edit-icon-container {
    padding: 0 1.5rem;
    align-items: initial;
  }

  label {
    margin-right: 0;
  }
}
</style>
