<template>
  <section class="up" v-show="!isContact">
  <form v-on:submit="editUserProfile('save')">
    <div>
      <div>
        <h3 v-if="!isEditProfile" class="up__title-first mb-4">Mon compte</h3>
        <h3 v-else-if="!isEditAvatar" class="up__title-first mb-4">Modifier mon compte</h3>

        <div class="up__user-icon">
          <a class="edit-avatar" v-if="isEditProfile && !isEditAvatar" @click="editUserProfile('avatar')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:v="https://vecta.io/nano"><path d="M51.2 353.28L0 512l158.72-51.2zm35.959-36.788L336.961 66.69l108.61 108.61-249.802 249.802zM504.32 79.36L432.64 7.68c-10.24-10.24-25.6-10.24-35.84 0l-23.04 23.04 107.52 107.52 23.04-23.04c10.24-10.24 10.24-25.6 0-35.84z"/></svg>
          </a>

          <span v-if="!isEditAvatar" class="user-points flex">{{userProfile.points}} Points</span>

          <img id="myAvatar" :src="userProfile.avatarUrl" style="height: 200px">
          <svg v-if="!userProfile.avatarUrl" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512"
               xmlns:v="https://vecta.io/nano">
            <path d="M256 0c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135S330.439 0 256 0zm0 240c-57.897 0-105-47.103-105-105S198.103 30 256 30s105 47.103 105 105-47.103 105-105 105zm167.966 118.195C387.006 320.667 338.009 300 286 300h-60c-52.008 0-101.006 20.667-137.966 58.195C51.255 395.539 31 444.833 31 497c0 8.284 6.716 15 15 15h420c8.284 0 15-6.716 15-15 0-52.167-20.255-101.461-57.034-138.805zM61.66 482c7.515-85.086 78.351-152 164.34-152h60c85.989 0 156.825 66.914 164.34 152H61.66z"/>
          </svg>
        </div>

        <p v-if="errorMessage" style="color: red">{{errorMessage}}</p>
        <p v-if="successMessage" style="color: green">{{successMessage}}</p>
        
        <div v-if="!isEditAvatar" class="up__inner flex justify-content-around">

          <div class="flex flex-wrap flex-col justify-center items-center up-list">

            <div class="up__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      placeholder="Pseudo"
                      aria-label="Pseudo"
                      :disabled="!isEditProfile"
                      v-model="userProfile.pseudo">
              </div>

            </div>

            <div class="up__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="email"
                      :disabled="!isEditProfile"
                      v-model="userProfile.email"
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon2">
              </div>

            </div>
            <br/>
            <div class="up__input">
              <label for="birthday" class="block text-sm font-medium text-gray-700">Date de naissance </label>
              <div class="flex items-center border-b border-teal-500 py-2">
                <input class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="date"
                      name='birthday'
                      disabled=true
                      v-model="userProfile.birthday"
                      placeholder="Date de naissance"
                      aria-label="Date de naissance"
                      aria-describedby="basic-addon2">
              </div>
            </div>

            <div v-if="isEditProfile" class="up__input">

              <div class="flex items-center border-b border-teal-500 py-2">
                <a href="forgot-password">Modifier mot de passe</a>
              </div>

            </div>

          </div>

        </div>

        <h3 v-if="!isEditAvatar" class="up__title-second pt-5">Centre d’intérêt</h3>

        <div v-if="!isEditAvatar" class="up__checkbox-container flex  flex-wrap pb-3">

          <div v-for="(checkbox, i) in checkboxArray"
              :key="i"
              class="up__checkbox-item">
            <div class="up__checkbox-item_inner">
              <label class="up__checkbox custom-control custom-checkbox flex ">
                <input type="checkbox"
                      :disabled="!isEditProfile"
                      v-model="userProfile.interests"
                      :value="checkbox"
                      class="form-checkbox h-4 w-4 text-gray-600 cursor-pointer self-center"
                      checked>
                <span class="ml-2 text-gray-700 cursor-pointer d-block">{{ checkbox }}</span>
              </label>
            </div>
          </div>

        </div>
      </div>

      <div v-if="!isEditProfile && !isEditAvatar" class="btn-wrapper flex justify-center">
        <button @click="editUserProfile('edit')"
                class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
          Modifier
        </button>

        <button @click="removeUserProfile(userProfile._id)"
                class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
          Supprimer
        </button>

        <button @click="contactUserProfile"
                class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
          Contact
        </button>
      </div>
      <div v-else-if="isEditProfile && !isEditAvatar">
        <button type="submit"
                class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
          Enregistrer
        </button>
      </div>

    </div>

    <div v-show="isEditAvatar">
      <edit-user-icon v-if="userProfile.avatar" :user="userProfile"></edit-user-icon>
    </div>

  </form>
  </section>

  <div v-show="isContact" class="up__inner flex justify-content-around">
    <div class="flex flex-wrap flex-col justify-center items-center up-list">
      <div class="up__input">
        <form v-on:submit="sendEmail">
            <label for="message" class="block text-sm font-medium text-gray-700">Ton message:</label>
            <div class="mt-1">
              <textarea v-model="message" required id="message" name="message" rows="4" class="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
            </div>

          <button type="submit" class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
            Envoyer
          </button>
          <button type="button" @click="contactUserProfile" class="ml-4 mr-4 bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded mt-3">
            Annuler
          </button>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import EditUserIcon from '@/components/account/edit-user-icon'

export default {
  name: 'UserProfile',
  components: {
    EditUserIcon
  },
  data () {
    return {
      userProfile: {
        _id:'',
        pseudo: '',
        confirmation: '',
        email: '',
        birthday: '',
        password: '',
        sex: '',
        postal_code: '',
        avatar: {},
        avatarURL: '',
        interests: [],
      },
      checkboxArray: ['Musique', 'Jeux', 'Сinéma', 'Lecture', 'Art', 'Sport', 'Technologie', 'Mouvement de jeunesse', 'Autre'],
      isEditProfile: false,
      isModifierUserIcon: false,
      testId: '60b8e71590842b8ec5b8c9d0',
      isEditAvatar: false,
      isContact: false,
      errorMessage: '',
      successMessage: '',
      message: ''
    }
  },
  async created () {
    let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
    const user = await axios.get(`/api/user/myProfil?${query}`)
      .then(r => {
        const { email, pseudo, interest: interests, birthday, avatarUrl, avatar, points, _id } = r.data

        this.userProfile = {
          _id,
          pseudo,
          email,
          interests,
          birthday: birthday.substr(0,10),
          avatarUrl,
          avatar,
          points
        }
      })
  },
  methods: {
    async editUserProfile (type) {
      if (type === 'avatar') this.isEditAvatar = true
      if (type === 'edit') this.isEditProfile = true
      if (type === 'save') {
        event.preventDefault()
        let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`

        await axios.put(`/api/user/${this.userProfile._id}?${query}`, this.userProfile)
          .then(r => {
            if (r.status === 200) {
              this.isEditProfile = false;
            }
          })
          .catch(e => {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
            this.errorMessage = e.response.data
          })
      }
    },
    async removeUserProfile (id) {
      if(confirm('Etes-vous sure de vouloir supprimer ce compte?') == true){
        let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`
        await axios.delete(`/api/user/${id}?${query}`)
        await this.$router.push('/login')
      }
    },
    contactUserProfile () {
      if (this.isContact != true) {
        this.isContact = true
      } else {
        this.isContact = false
      }
    },
    async sendEmail () {
      event.preventDefault()
      let emailObject = {
        message: this.message,
        sender: this.userProfile
      }
      let query = `secret_token=${JSON.parse(localStorage.getItem('token'))}`

      await axios.post(`/api/user/sendMail?${query}`, emailObject)
        .then(r => {
          if (r.status === 200) {
            this.isContact = false
            this.message = ''
            this.successMessage = "Nous avons bien reçu ton message! Nous te recontacterons le plus vite possible par email."
          }
        })
        .catch(e => {
          this.isContact = false
          this.errorMessage = e.response.data
        })
    }
  }
}

</script>

<style scoped>
  .up {
    padding: 3rem 0;
  }

  .up__input {
    max-width: 45%;
    width: 100%;
    margin: 0 1rem;
  }

  .up__inner,
  .up__checkbox-container {
    max-width: 725px;
    margin: 0 auto;
    width: 100%;
    padding: 0 1rem;
  }

  .up__checkbox {
    width: 100%;
    text-align: left;
  }

  .up__checkbox-item {
    max-width: 230px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }

  .up__title-first,
  .up__title-second {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .user-points {
    position: absolute;
    bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    background-color: #fff;
    border-radius: 6px;
    padding: 2px;
    border: 1px solid #46505e;
    display: flex;
    text-align: right;
  }

  .up__checkbox-item_inner {
    display: flex;
    max-width: 200px;
    margin: 0 auto;
    width: 100%;
  }

  .up__user-icon {
    max-width: 200px;
    width: 100%;
    border-radius: 4px;
    border: 2px solid #000;
    display: flex;
    justify-content: center;
    margin: 2rem auto;
    position: relative;
  }

  .up__user-icon > svg {
    max-width: 150px;
    padding: 2.5rem 1rem;
  }

  .up-list {
    width: 100%;
  }

  .edit-avatar {
    position: absolute;
    right: 0px;
    bottom: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    .up__inner > .flex.flex-wrap {
      flex-direction: column;
      align-content: center;
    }

    .up__inner {
      justify-content: center;
    }

    .up__input {
      max-width: initial;
    }

    .up {
      padding: 1rem 0;
    }

    .up__checkbox-item {
      margin: 0.5rem 0;
    }

    .up__checkbox {
      max-width: 200px;
    }

    .up__checkbox-container {
      justify-content: center;
    }
  }

  @media screen and (max-width: 560px) {
    .up__inner > .flex.flex-wrap {
      flex-direction: column;
    }

    .btn-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
</style>
